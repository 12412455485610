const colorMatrix = [
  {name: 'babyblau', hex: '#A1CAF1'},
  {name: 'hellorange', hex: '#FFA500'},
  {name: 'grafit', hex: '#474A51'},
  {name: 'ozean', hex: '#4F42B5'},
  {name: 'neongrün', hex: '#39FF14'},
  {name: 'sattelbraun', hex: '#8B4513'},
  {name: 'traube', hex: '#6F2DA8'},
  {name: 'honig', hex: '#FFC30B'},
  {name: 'karibikgrün', hex: '#00CC99'},
  {name: 'taube', hex: '#BBC8D3'},
  {name: 'blau', hex: '#0000FF'},
  {name: 'grau', hex: '#808080'},
  {name: 'meliert', hex: '#CFCFCF'},
  {name: 'royalblau', hex: '#4169E1'},
  {name: 'anthrazit', hex: '#2F353B'},
  {name: 'hellgrau', hex: '#D3D3D3'},
  {name: 'mauve', hex: '#E0B0FF'},
  {name: 'apfelgrün', hex: '#8DB600'},
  {name: 'schwarz', hex: '#000000'},
  {name: 'bordeaux', hex: '#7B1A36'},
  {name: 'braun', hex: '#964B00'},
  {name: 'senf', hex: '#FFDB58'},
  {name: 'rot', hex: '#FF0000'},
  {name: 'türkis', hex: '#40E0D0'},
  {name: 'weinrot', hex: '#722F37'},
  {name: 'walnuss', hex: '#8B5A2B'},
  {name: 'lagune', hex: '#0095B6'},
  {name: 'petrol', hex: '#006C67'},
  {name: 'edelstahl', hex: '#D0CFCC'},
  {name: 'baldrian', hex: '#C3B1E1'},
  {name: 'beige', hex: '#F5F5DC'},
  {name: 'dunkelblau', hex: '#00008B'},
  {name: 'natur', hex: '#FAF0E6'},
  {name: 'altrosa', hex: '#D48B91'},
  {name: 'dunkelgrün', hex: '#013220'},
  {name: 'catnip', hex: '#A2C523'},
  {name: 'schokobraun', hex: '#5D3A1A'},
  {name: 'brombeer', hex: '#8A2BE2'},
  {name: 'taubenblau', hex: '#5C8A8A'},
  {name: 'grün', hex: '#008000'},
  {name: 'neonorange', hex: '#FF5F1F'},
  {name: 'marineblau', hex: '#000080'},
  {name: 'pink', hex: '#FFC0CB'},
  {name: 'violett', hex: '#8F00FF'},
  {name: 'weiß', hex: '#FFFFFF'},
  {name: 'magenta', hex: '#FF00FF'},
  {name: 'himbeer', hex: '#E30B5C'},
  {name: 'steingrau', hex: '#7E7F7A'},
  {name: 'dunkelgrau', hex: '#A9A9A9'},
  {name: 'burgunder', hex: '#800020'},
  {name: 'rosa', hex: '#FF66CC'},
  {name: 'lila', hex: '#800080'},
  {name: 'limette', hex: '#BFFF00'},
  {name: 'champagner', hex: '#F7E7CE'},
  {name: 'gelb', hex: '#FFFF00'},
  {name: 'orange', hex: '#FFA500'},
  {name: 'graublau', hex: '#607D8B'},
  {name: 'coral', hex: '#FF7F50'},
  {name: 'faultier', hex: '#8E795E'},
  {name: 'affe', hex: '#5C4033'},
  {name: 'koala', hex: '#A9A9A9'},
  {name: 'schlamm', hex: '#7F6A4F'},
  {name: 'rose', hex: '#FF007F'},
  {name: 'dunkelbraun', hex: '#654321'},
  {name: 'salbei', hex: '#9EA587'},
  {name: 'ocker', hex: '#CC7722'},
  {name: 'creme', hex: '#FFFDD0'},
  {name: 'mint', hex: '#98FF98'},
  {name: 'sand', hex: '#C2B280'},
  {name: 'taupe', hex: '#483C32'},
  {name: 'hellblau', hex: '#ADD8E6'},
  {name: 'hellbraun', hex: '#CD853F'},
  {name: 'weiss', hex: '#FFFFFF'},
  {name: 'oliv', hex: '#808000'},
  {name: 'silber', hex: '#C0C0C0'},
  {name: 'rosegold', hex: '#B76E79'},
  {name: 'gold', hex: '#FFD700'},
  {name: 'lindgrün', hex: '#32CD32'},
  {name: 'eisblau', hex: '#AFDBF5'},
  {name: 'lichtgrau', hex: '#D3D3D3'},
  {name: 'olivgrün', hex: '#556B2F'},
  {name: 'cappuccino', hex: '#A67B5B'},
  {name: 'flieder', hex: '#E6E6FA'},
  {name: 'transparent', hex: '#F0F8FF'},
  {name: 'platingrau', hex: '#DADADA'},
  {name: 'pastellrot', hex: '#FF6961'},
  {name: 'papaya', hex: '#FFEFD5'},
  {name: 'neongelb', hex: '#FFFF33'},
  {name: 'cognac', hex: '#9A463D'},
  {name: 'khaki', hex: '#F0E68C'},
  {name: 'camouflage', hex: '#78866B'},
  {name: 'tabak', hex: '#B57A4D'},
  {name: 'terracotta', hex: '#E2725B'},
  {name: 'stein', hex: '#8D948D'},
  {name: 'karamell', hex: '#85461E'},
  {name: 'navy', hex: '#000080'},
  {name: 'hellgrün', hex: '#90EE90'},
  {name: 'leo', hex: '#A0522D'},
  {name: 'zink', hex: '#4D4D4D'},
  {name: 'd', hex: '#6D6E71'},
  {name: 'fuchsia', hex: '#FF00FF'},
  {name: 'sterne', hex: '#F5F5F5'},
  {name: 'leopard', hex: '#B8860B'},
  {name: 'silberweiß', hex: '#F5F5DC'},
  {name: 'bunt', hex: '#FF6347'},
  {name: 'hellrosa', hex: '#FFB6C1'},
  {name: 'mocca', hex: '#3B2F2F'},
  {name: 'apricot', hex: '#FBCEB1'},
  {name: 'beere', hex: '#D7263D'},
  {name: 'hummer', hex: '#FF6347'},
  {name: 'hafer', hex: '#E4D5B7'},
  {name: 'zimt', hex: '#D2691E'},
  {name: 'koriander', hex: '#C8B576'},
  {name: 'pflaume', hex: '#8E4585'},
  {name: 'himbeere', hex: '#B3446C'},
  {name: 'farblich', hex: '#FFFFFF'},
  {name: 'sortiert', hex: '#CCCCCC'},
  {name: 'angel', hex: '#F8F8FF'},
  {name: 'aqua', hex: '#00FFFF'},
  {name: 'marmor', hex: '#DADADA'},
  {name: 'starry', hex: '#000033'},
  {name: 'night', hex: '#191970'},
  {name: 'graphit', hex: '#383838'},
  {name: 'concrete', hex: '#D4D2CD'},
  {name: 'mit', hex: '#FFDEAD'},
  {name: 'schlaufe', hex: '#F08080'},
  {name: 'ohne', hex: '#F5F5F5'},
  {name: 'rotbraun', hex: '#80341F'},
  {name: 'lime', hex: '#00FF00'},
  {name: 'neonpink', hex: '#FF1493'},

  {
    name: 'weiß/grau',
    hex: 'linear-gradient(-45deg, #ffffff 50%, #808080 50%)',
  },
];

const findColor = (val) => {
  let valArr = val.replace('-', '/').split('/');

  if (valArr && valArr.length == 1) {
    const color = colorMatrix.find((item) => item.name === valArr[0]);
    if (color && color.hex) {
      return color.hex;
    } else {
      // console.log("Color Missing: ");
      // console.log(valArr[0]);
    }
  } else if (valArr && valArr.length == 2) {
    const color1 = colorMatrix.find((item) => item.name === valArr[0]);
    const color2 = colorMatrix.find((item) => item.name === valArr[1]);
    if (color1 && color1.hex && color2 && color2.hex) {
      return `linear-gradient(-45deg, ${color1.hex} 50%, ${color2.hex} 50%)`;
    } else {
      // console.log("One or more of these two colors Missing: ");
      // console.log(valArr[0]);
      // console.log(valArr[1]);
    }
  } else if (valArr && valArr.length == 3) {
    const color1 = colorMatrix.find((item) => item.name === valArr[0]);
    const color2 = colorMatrix.find((item) => item.name === valArr[1]);
    const color3 = colorMatrix.find((item) => item.name === valArr[2]);
    if (color1 && color1.hex && color2 && color2.hex && color3 && color3.hex) {
      return `linear-gradient(-45deg, ${color1.hex} 33.33%, ${color2.hex} 33.33%,${color2.hex} 66.66%, ${color3.hex} 66.66%)`;
    } else {
      // console.log("One or more of these three colors Missing: ");
      // console.log(valArr[0]);
      // console.log(valArr[1]);
      // console.log(valArr[2]);
    }
  }
};

export {findColor};
