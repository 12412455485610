export default defineNuxtPlugin((nuxtApp) => {
  const store = useDefaultStore();
  const router = useRouter();

  router.beforeEach((to, from, next) => {
    if (store.getPrefetchedRoutes && store.getPrefetchedRoutes.length > 25) {
      store.setPrefetchedRoutes(store.prefetchedRoutes.slice(0, 25));
    }

    return next();
  });
  router.afterEach((to, from) => {
    if (to.path === from.path) {
      return;
    } else {
      const {prefetchListing} = usePrefetchListing();
      const currentCategory = store.getCurrentCategory || store.rootCategory;
      //   if (
      //     currentCategory &&
      //     currentCategory.seoUrls?.[0]?.seoPathInfo &&
      //     "/" + currentCategory.seoUrls?.[0]?.seoPathInfo == route.fullPath &&
      //     store.getCurrentListing
      //   ) {
      //     store.addPrefetchedRoutes(store.getCurrentListing);
      //   }
      if (
        currentCategory &&
        currentCategory.children &&
        currentCategory.children.length
      ) {
        currentCategory.children.forEach((item) => {
          prefetchListing(item.id);
        });
      }
    }
  });
});
