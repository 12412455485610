<template>
  <div
    class="wishlist"
    :class="{'wishlist--absolute': absolute, pulse: pulseItem}"
    @click="
      toggleWishlistItem();
      pulseItem = isInWishlist;
    "
  >
    <div
      class="wishlist__inner"
      :class="{
        'wishlist__inner--active': isInWishlist,
        'wishlist__inner--text': text,
      }"
    >
      <div class="wishlist__icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.248"
          height="15.931"
          viewBox="0 0 20.248 15.931"
        >
          <g id="Gruppe_50" data-name="Gruppe 50" transform="translate(1 1)">
            <path
              id="Vereinigungsmenge_1"
              data-name="Vereinigungsmenge 1"
              d="M6.33,13.009a4.771,4.771,0,0,1-.559-.481l-4.385-4.4a4.765,4.765,0,0,1,0-6.72L1.4,1.392a4.718,4.718,0,0,1,6.692,0L9.129,2.435,10.157,1.4a4.718,4.718,0,0,1,6.692,0l.013.013a4.766,4.766,0,0,1,0,6.72l-4.385,4.4a4.718,4.718,0,0,1-6.147.471Z"
              transform="translate(0 0)"
              fill="none"
              stroke-width="2"
            />
          </g>
        </svg>
      </div>
      <div v-if="text" class="wishlist__text">Auf die Merkliste</div>
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  product: {
    default: () => ({}),
    type: Object,
  },
  absolute: {
    type: Boolean,
    default: false,
  },
  text: {
    type: Boolean,
    default: false,
  },
  isInCart: {
    type: Boolean,
    default: false,
  },
});

const pulseItem = ref(false);

const {isInWishlist, toggleWishlistItem} = useWishlist(props.product);
</script>

<style lang="scss">
.wishlist {
  &--absolute {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

.wishlist__inner {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  border-radius: $radius-small;
  z-index: 20;
  cursor: pointer;

  svg path {
    stroke: $color-dark-red;
  }

  &:hover {
    svg path {
      fill: $color-dark-red;
    }
  }

  &--active {
    background-color: $color-light-red;

    svg path {
      fill: $color-dark-red;
    }
  }

  &--text {
    justify-content: flex-start;
    width: auto;
    height: auto;

    &.wishlist__inner--active {
      background: transparent;
    }

    .wishlist__icon {
      margin-right: $spacing-xxs;
    }

    .wishlist__text {
      font-size: $text-xs;
    }
  }
}
</style>
