<template>
  <div
    class="checkbox"
    :class="{
      'is-active': selected,
      'is-disabled': disabled,
      'has-error': !valid,
      'is-required': required,
      'no-checkbox': noCheckbox,
      'checkbox--yellow': yellow,
      'checkbox--red': red,
      'checkbox--green': green,
      'checkbox--black': black,
      'checkbox--gray': gray,
      'checkbox--padding': padding,
      'checkbox--padding-x': paddingX,
      'transparent-spacing': transparentSpacing,
      dropdown: dropdown,
      'checkbox--small': xsLabel || smLabel,
    }"
    :data-testid="name"
    input-wrapper
  >
    <label class="checkbox__container">
      <slot name="checkmark" v-bind="{selected, disabled}">
        <div
          v-if="!noCheckbox"
          class="checkbox__checkmark"
          :class="{'checkbox__checkmark is-active': selected || model}"
        >
          <div class="checkbox__icon icon-check"></div>
        </div>
      </slot>
      <input
        type="checkbox"
        :name="name"
        :value="value"
        :checked="selected || model"
        :disabled="disabled"
        :required="required"
        class="checkbox__input"
        @change="changeEvent($event)"
        v-model="model"
      />
      <slot name="label" v-bind="{label, selected, disabled}">
        <div
          v-if="label"
          class="checkbox__label"
          :class="{
            'checkbox__label--xs': xsLabel,
            'checkbox__label--sm': smLabel,
          }"
          v-html="label"
        ></div>
      </slot>
    </label>
    <div class="checkbox__message">
      <transition name="fade">
        <!-- @slot Custom message of form input -->
        <slot
          v-if="!disabled"
          :name="computedMessageSlotName"
          v-bind="{computedMessage}"
        >
          <div :class="computedMessageClass">{{ computedMessage }}</div>
        </slot>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const emit = defineEmits(['change']);
const model = defineModel();

const changeEvent = (event) => {
  emit('change', event);
};

const props = defineProps({
  selected: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Boolean],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  /**
   *  Hint/Required message value of checkbox.
   */
  hintMessage: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  /**
   * Info/success message value of select.
   */
  infoMessage: {
    type: String,
    default: '',
  },
  /**
   * Error message value of select. It will be appeared if `valid` is `true`.
   */
  errorMessage: {
    type: String,
    default: 'This field is not correct.',
  },
  valid: {
    type: Boolean,
    default: true,
  },
  noCheckbox: {
    type: Boolean,
    default: false,
  },
  dropdown: {
    type: Boolean,
    default: false,
  },

  transparentSpacing: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  xsLabel: {
    type: Boolean,
    default: false,
  },
  smLabel: {
    type: Boolean,
    default: false,
  },
  yellow: {
    type: Boolean,
    default: false,
  },
  red: {
    type: Boolean,
    default: false,
  },
  green: {
    type: Boolean,
    default: false,
  },
  black: {
    type: Boolean,
    default: false,
  },
  gray: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: Boolean,
    default: false,
  },
  paddingX: {
    type: Boolean,
    default: false,
  },
});

const messagesHandler = (error, info, hint) => {
  if (props.errorMessage && !props.valid) {
    return error;
  } else if (props.infoMessage && props.valid) {
    return info;
  } else if (props.hintMessage) {
    return hint;
  } else {
    return '';
  }
};

const computedMessageSlotName = computed(() => {
  return messagesHandler(
    'show-error-message',
    'show-info-message',
    props.required ? 'show-hint-message' : ''
  );
});
const computedMessage = computed(() => {
  return messagesHandler(
    props.errorMessage,
    props.infoMessage,
    props.required ? props.hintMessage : ''
  );
});
const computedMessageClass = computed(() => {
  return messagesHandler(
    'checkbox__message--error',
    'checkbox__message--info',
    props.required ? 'checkbox__message--hint' : ''
  );
});
</script>

<style lang="scss">
.checkbox {
  padding: $spacing-base 0;
  cursor: pointer;

  &.has-error,
  &.invalid {
    border: 2px solid $color-red;
    border-radius: $radius-small;
    background: lighten($color-red, 20%) !important;
  }

  &--yellow {
    background: $color-light-yellow;
    border-radius: $radius-small;
    padding: $spacing-base;
  }
  &--red {
    background: $color-light-red;
    border-radius: $radius-small;
    padding: $spacing-base;
  }
  &--green {
    background: $color-light-green;
    border-radius: $radius-small;
    padding: $spacing-base;
  }
  &--black {
    background: $color-black;
    border-radius: $radius-small;
    padding: $spacing-base;
    color: $color-white;
  }
  &--gray {
    background: $color-light-gray;
    border-radius: $radius-small;
    padding: $spacing-base;
  }
  &--padding {
    padding: $spacing-base;
  }
  &--padding-x {
    padding: 0 $spacing-base;
  }

  &--small {
    padding: 0 0 $spacing-base 0;
  }

  &.no-checkbox:not(.media-filter__item) {
    border: 2px solid $color-gray;
    border-radius: $radius-small;
    display: inline-block;
  }

  &.no-checkbox {
    background: $color-white;
    border-radius: $radius-small;
    padding: 0;
    margin-right: 0;

    .checkbox__container {
      @include padding-sm;
    }

    &.is-active {
      border: 2px solid $color-black;
      background-color: $color-black;
      color: $color-white;
    }
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.checkbox__container {
  cursor: pointer;
  display: flex;
  // align-items: center;
  width: auto;
}

.checkbox__input {
  opacity: 0;
  position: absolute;
  z-index: -10;

  &:focus + .checkbox__checkmark {
    outline: 2px solid $color-green;
  }
}

.checkbox__checkmark {
  width: 1.5rem;
  height: 1.5rem;
  flex: 0 0 1.5rem;
  border: 2px solid $color-black;
  border-radius: $radius-small;
  margin-right: $spacing-xs;
  position: relative;
  background-color: $color-white;
  z-index: 1;

  &.is-active {
    background: $color-green;
    color: $color-white;
    border: 2px solid $color-green;

    .checkbox__icon {
      color: $color-white;
      display: block;
    }
  }
}

.checkbox__icon {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: $color-black;
}

.checkbox__label {
  &--xs {
    font-size: $text-xxs;
  }

  &--sm {
    font-size: $text-sm;
  }
}
</style>
