<template>
  <div :class="`alert-color-${type}`" class="alert">
    <slot name="message" v-bind="{message}">
      <span v-if="message" class="alert__message">{{ message }}</span>
    </slot>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  message: {
    type: String,
    default: '',
  },

  type: {
    type: String,
    default: 'secondary',
    validator: function (value) {
      return ['secondary', 'info', 'success', 'warning', 'danger'].includes(
        value
      );
    },
  },
});

const icon = computed(() => {
  switch (props.type) {
    case 'success':
      return 'added_to_cart';
    case 'danger':
      return 'info_shield';
    default:
      return 'info_circle';
  }
});
</script>

<style lang="scss">
.alert {
  display: block;
  padding: $spacing-base;
  background-color: $color-beige;
  margin-bottom: $spacing-base;
  border-radius: $radius-small;
}

.alert-color-success {
  background-color: $color-green;
  color: $color-white;
}

.alert-color-danger,
.alert-color-warning {
  background: $color-red;
  color: $color-white;
}

.alert-color-info {
  background-color: $color-beige;
  color: $color-black;
}
</style>
