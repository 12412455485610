<template>
  <component
    :is="titleType"
    class="product-title"
    :class="[
      clamp > 0 ? `product-title--clamp-${clamp}` : '',
      detail ? 'product-title--detail' : '',
      modal ? 'product-title--modal' : '',
    ]"
  >
    <template
      v-if="
        store.getStrapiSeo &&
        store.getStrapiSeo.CustomProductTitle &&
        store.getStrapiSeo.Path === $route.path &&
        detail
      "
    >
      {{ store.getStrapiSeo.CustomProductTitle }}
    </template>
    <template v-else>
      <span
        v-if="
          (detail || showBrand) &&
          product &&
          product.extensions &&
          product.extensions.productBrand &&
          product.extensions.productBrand[0] &&
          product.extensions.productBrand[0].brand &&
          product.extensions.productBrand[0].brand.name
        "
        class="product-title__brand"
        :class="{'product-title__brand--bold': boldBrand}"
        >{{ product.extensions.productBrand[0].brand.name }}</span
      >
      {{ getName }}
    </template>
  </component>
</template>
<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  product: {
    required: true,
    type: Object,
  },
  clamp: {
    type: Number,
    default: 0,
  },
  detail: {
    type: Boolean,
    default: false,
  },
  modal: {
    type: Boolean,
    default: false,
  },
  showBrand: {
    type: Boolean,
    default: false,
  },
  boldBrand: {
    type: Boolean,
    default: false,
  },
});

const getName = computed(() => {
  if (
    props.product &&
    props.product.extensions &&
    props.product.extensions.productBrand &&
    props.product.extensions.productBrand.length
  ) {
    return getProductName({product: props.product})
      .replace("'", '´')
      .replace('`', '´')
      .replace('´', "'")
      .replace(props.product.extensions.productBrand[0]?.brand?.name, '');
  } else {
    return getProductName({product: props.product});
  }
});
const titleType = computed(() => {
  if (props.detail) {
    return 'h1';
  }

  return 'span';
});
</script>

<style lang="scss">
.product-title {
  width: 100%;
  font-weight: $font-normal;

  &--detail {
    font-size: $text-xl;

    @media (min-width: $md) {
      font-size: $text-3xl;
      font-weight: $font-bold;
    }
  }

  &--modal {
    font-size: $text-xl;
    font-weight: $font-bold;
    display: block;
  }

  &--clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    @media (min-width: $md) {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &--clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &--clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 12px;
  }
}

.product-title__brand {
  display: block;
  // font-size: $text-base;
  // font-weight: $font-light;

  @media (min-width: $md) {
    font-weight: $font-normal;
    max-width: calc(100% - 120px);
  }

  &--bold {
    font-weight: $font-bold !important;
  }
}
</style>
