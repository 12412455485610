import validate from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/middleware/auth.global.ts";
import pages_45global from "/app/middleware/pages.global.ts";
import redirects_45global from "/app/middleware/redirects.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  pages_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}