<template>
  <div
    class="card"
    :class="{
      'card--beige': beige,
      'card--gray': gray,
      'card--black': black,
      'card--blue': blue,
      'card--yellow': yellow,
      'card--no-margin': noMargin,
      'card--small': small,
      'card--no-border': noBorder,
    }"
  >
    <div class="card__inner">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  beige: {
    type: Boolean,
    default: false,
  },
  gray: {
    type: Boolean,
    default: false,
  },
  black: {
    type: Boolean,
    default: false,
  },
  blue: {
    type: Boolean,
    default: false,
  },
  yellow: {
    type: Boolean,
    default: false,
  },
  noMargin: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  noBorder: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss">
.card {
  padding: $spacing-base;

  border-radius: $radius-small;
  margin-bottom: $spacing-base;
  background-color: $color-white;
  border: 2px solid $color-gray;

  &--small {
    padding: $spacing-xs $spacing-base;
  }

  &--beige {
    background-color: $color-beige;
  }

  &--gray {
    background-color: $color-light-gray;
    border: none;
  }
  &--blue {
    background-color: $color-light-blue;
    border: none;
  }
  &--yellow {
    background-color: $color-light-yellow;
    border: none;
  }

  &--black {
    background-color: $color-black;
    color: $color-white;
    border: none;
  }

  &--no-margin {
    margin-bottom: 0px;
  }

  &--no-border {
    border: none;
  }
}
</style>
