import { defineStore } from "pinia";
import type {Schemas} from "@shopware/api-client/api-types";

export const useShopwareStore = defineStore("shopware", {
  state: () => {
    return {
      contextToken: null as any,
      context:  null as any,
      user:  null as any,
      countries:  null as any,
      languages:  null as any,
      salutations: null as Schemas["Salutation"][] | null,
      currencies:  null as any,
      addresses:  null as any,
      cart: {},
      wishlist: {},
      shippingMethods:  null as any,
      paymentMethods:  null as any,
    };
  },

  // contains your mutations
  actions: {
    setContextToken(value: any) {
      this.contextToken = value;
    },
    setContext(value: any) {
      this.context = value;
    },
    setUser(value: any) {
      this.user = value;
    },
    setCountries(value: any) {
      this.countries = value;
    },
    setLanguages(value: any) {
      this.languages = value;
    },
    setSalutations(value: any) {
      this.salutations = value;
    },
    setCurrencies(value: any) {
      this.currencies = value;
    },
    setAddresses(value: any) {
      this.addresses = value;
    },
    setCart(value: any) {
      this.cart = value;
    },
    setWishlist(value: any) {
      this.wishlist = value;
    },
    setShippingMethods(value: any) {
      this.shippingMethods = value;
    },
    setPaymentMethods(value: any) {
      this.paymentMethods = value;
    },
  },
  // your root getters
  getters: {
    getContextToken() : any {
      return this.contextToken;
    },
    getContext() : any {
      return this.context;
    },
    getUser() : any {
      return this.user;
    },
    getCart()  : any{
      return this.cart;
    },
    getWishlist() : any{
      return this.wishlist;
    },
    getCountries() : any{
      return this.countries;
    },
    getLanguages() : any{
      return this.languages;
    },
    getSalutations() : Schemas["Salutation"][] | null {
      return this.salutations;
    },
    getCurrencies() : any {
      return this.currencies;
    },
    getAddresses() : any {
      return this.addresses;
    },
    getShippingMethods() : any {
      return this.shippingMethods;
    },
    getPaymentMethods() : any {
      return this.paymentMethods;
    },
  },
});
