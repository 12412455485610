interface Route {
  path: string;
  query: any;
  fullPath: string;
  hash: String;
}

export interface Root {
  apiAlias: string;
  product: Product;
  configurator: Configurator[];
}

export interface Product {
  seoUrls: SeoUrl[];
  apiAlias: string;
}

export interface SeoUrl {
  seoPathInfo: string;
  apiAlias: string;
}

export interface Configurator {
  apiAlias: string;
}

export default defineNuxtRouteMiddleware(async (to: Route) => {
  const route = to;
  const store = useDefaultStore();
  const {swRequest} = useRequest();

  if (route.path.startsWith('/detail/') && store.getCurrentProduct) {
    let correctProductPath = null;
    await swRequest(`/product/${store.getCurrentProduct.id}`, {
      includes: {
        product: ['seoUrls'],
        property_group: [],
        seo_url: ['seoPathInfo'],
      },
      associations: {
        seoUrls: {},
      },
    }).then((res: unknown) => {
      const root = res as Root;
      const seoPath = root?.product?.seoUrls?.[0]?.seoPathInfo;
      if (seoPath) correctProductPath = seoPath;
    });

    if (correctProductPath) {
      const {path, query, hash} = route;
      return navigateTo({
        path: '/' + correctProductPath,
        query,
        hash,
        redirectCode: 301,
      });
    }
  }

  //QR-Code Redirect and trailing slash redirect

  if (route.path !== '/' && route.path.endsWith('qr-04-24')) {
    const {path, query, hash} = route;
    const nextPath = '/';
    const nextRoute = {path: nextPath, query, hash};

    return navigateTo({...nextRoute, redirectCode: 301});
  } else if (route.path !== '/' && route.path.endsWith('/')) {
    const {path, query, hash} = route;
    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute = {path: nextPath, query, hash};

    return navigateTo({...nextRoute, redirectCode: 301});
  } else if (route.path !== '/' && route.fullPath.endsWith('%2F')) {
    const {fullPath, path, query, hash} = route;
    const decodedFullPath = decodeURIComponent(fullPath);
    const nextPath = decodedFullPath.slice(0, -1);
    const nextRoute = {path: nextPath, query, hash};

    return navigateTo({...nextRoute, redirectCode: 301});
  }

  //Magazine Redirect
  const routeFragments = route.path.split('/');

  if (
    routeFragments.length > 3 &&
    routeFragments[1] === 'magazin' &&
    routeFragments[2] === 'hunde'
  ) {
    const nextPath = route.path.replace('magazin/hunde', 'magazin/artikel');
    const nextRoute = {path: nextPath, query: route.query, hash: route.hash};

    return navigateTo({...nextRoute, redirectCode: 301});
  }
});
