export function useWishlist(product: any) {
  const {requestHeaders, requestPath} = utilRequests();
  const app = useNuxtApp();
  const shopwareStore = useShopwareStore();
  const {user, isLoggedIn, isGuestSession} = useUser();
  const tempStore = useTempStore();

  const {getCategoryObject, getCategoryName} = useCategory();
  const {sendNotification} = useNotification();

  const handleLocalWishlist = (add) => {
    let list = tempStore.getLocalWishlist;
    if (add) {
      //add to List
      list.push(product.id);
    } else {
      //Remove from List
      list = list.filter((el) => el !== product.id);
    }
    tempStore.setLocalWishlist(list);
    if (process.client) {
      localStorage.setItem('wishlist', JSON.stringify(list));
    }
  };

  const currentCategoryName = product?.categoryIds?.length
    ? getCategoryName(product.categoryIds[product.categoryIds.length - 1])
    : null;

  const pushDataLayer = (add) => {
    window.dataLayer.push({
      event: add ? 'add_to_wishlist' : 'remove_from_wishlist',
      ecommerce: {
        items: [
          {
            item_name: product.name ? product.name : product.label,
            item_category: currentCategoryName,
            item_id: product.productNumber,
            item_brand:
              product.extensions?.productBrand?.[0]?.brand?.name || null,
            quantity: 1,
            price: product.price
              ? product.price.unitPrice
              : product.calculatedPrice?.unitPrice,
            currency: 'EUR',
          },
        ],
      },
    });
    window.pagesense = window.pagesense || [];
    window.pagesense.push(['trackEvent', 'AddToWishlist']);
  };

  const fetchWishlist = (firstLogin: Boolean = false) => {
    if (
      tempStore.getLocalWishlist &&
      tempStore.getLocalWishlist.length &&
      isLoggedIn.value &&
      !isGuestSession.value
    ) {
      mergeWishlist(tempStore.getLocalWishlist);
    } else if (isLoggedIn.value && !isGuestSession.value && !firstLogin) {
      $fetch(`${requestPath}/customer/wishlist`, {
        method: 'POST',
        ...requestHeaders.value,
        body: {},
      })
        .then((response) => {
          shopwareStore.setWishlist(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const mergeWishlist = (productList) => {
    $fetch(`${requestPath}/customer/wishlist/merge`, {
      method: 'POST',
      ...requestHeaders.value,
      body: {productIds: productList},
    })
      .then((response) => {
        tempStore.setLocalWishlist([]);
        if (process.client) {
          localStorage.removeItem('wishlist');
        }

        fetchWishlist();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addToWishlist = () => {
    if (isLoggedIn.value && !isGuestSession.value) {
      $fetch(`${requestPath}/customer/wishlist/add/${product.id}`, {
        method: 'POST',
        ...requestHeaders.value,
        body: {},
      })
        .then((response) => {
          fetchWishlist();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      handleLocalWishlist(true);
    }

    //Send notification
    sendNotification({
      title: 'Produkt zum Wunschzettel hinzugefügt',
      type: 'success',
    });

    // Finally Push to Datalayer
    pushDataLayer(true);
  };

  const removeFromWishList = () => {
    if (isLoggedIn.value && !isGuestSession.value) {
      $fetch(`${requestPath}/customer/wishlist/delete/${product.id}`, {
        method: 'DELETE',
        ...requestHeaders.value,
        body: {},
      })
        .then((response) => {
          fetchWishlist();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      handleLocalWishlist(false);
    }

    //send notification
    sendNotification({
      title: 'Produkt vom Wunschzettel entfernt',
      type: 'success',
      icon: 'delete',
    });

    pushDataLayer(false);
  };

  const isInWishlist = computed(() => {
    if (
      (shopwareStore.wishlist &&
        shopwareStore.wishlist?.products?.elements?.find(
          (el) => el.id === product.id
        )) ||
      tempStore.getLocalWishlist.includes(product.id)
    ) {
      return true;
    } else {
      return false;
    }
  });

  const toggleWishlistItem = () => {
    if (isInWishlist.value) {
      removeFromWishList();
    } else addToWishlist();
  };

  return {
    fetchWishlist,
    addToWishlist,
    removeFromWishList,
    isInWishlist,
    toggleWishlistItem,
  };
}
