export type Route = {
  path: string;
  fullPath: string;
  query: {slug: string};
  params: {slug: string[]};
};

export function useStrapi({route}: {route: Route}) {
  const app = useNuxtApp();
  const store = useDefaultStore();

  const {strapiRequest} = useRequest();

  const findStage = (obj: any) => {
    return obj && obj.attributes && obj.attributes.Stage;
  };

  const findParentStage = async (path: string) => {
    await strapiRequest(
      `/pages/?filters[Path][$eqi]=/${encodeURIComponent(
        path
      )}&populate=deep,10`
    ).then((res: any) => {
      const data = res?.data?.[0];
      if (data && findStage(data)) {
        const defaultStage = data.attributes.Stage;
        store.setFallbackStage({
          ...defaultStage,
          Subheadline: null,
          Description: null,
          AddSearch: false,
          NoMargin: true,
          Background: lightenColor(defaultStage.Background, 8),
          Headline: store.getCurrentCategory?.customFields
            ?.custom_category_headline
            ? store.getCurrentCategory?.customFields?.custom_category_headline
            : store.getCurrentCategory?.name,
        });
      } else {
        const newPathArray = path.split('/').slice(0, -1);
        const newPath = newPathArray.join('/');

        if (newPathArray.length) {
          findParentStage(newPath);
        } else {
          return;
        }
      }
    });
  };

  //Generate a fallback stage
  const getFallbackStage = async () => {
    // Check if Content has a stage
    if (store?.getStrapiContent?.[0]?.attributes?.Stage) {
      return;
    } else {
      if (route?.params?.slug?.length) {
        //Slice path to find parent stage
        findParentStage(route.params.slug.slice(0, -1).join('/'));
      }
    }
  };

  const getStrapiPage = async () => {
    const pageType: string = getPageType(route);
    const isPreview: boolean = route.path.startsWith(`/api/preview`);
    let apiPath: string | undefined;
    let seoPath: string = 'seo-sections';

    // Define apiPath using a switch-case
    if (pageType === 'magazine') {
      apiPath = 'magazines';
    } else if (pageType === 'landingpage') {
      apiPath = 'landingpages';
    } else if (pageType === 'ads') {
      apiPath = 'ads';
    } else if (pageType === 'product') {
      seoPath = 'seo-section-products';
    } else {
      apiPath = 'pages';
    }

    const url: string = isPreview
      ? `${
          app.$config.public.strapi.url
        }/api/${apiPath}?publicationState=preview&filters[Path][$eqi]=${encodeURIComponent(
          route.query.slug
        )}&populate=deep,10`
      : `${
          app.$config.public.strapi.url
        }/api/${apiPath}?filters[Path][$eqi]=${encodeURIComponent(
          route.path
        )}&populate=deep,10`;

    if (apiPath) {
      await $fetch(url)
        .then(async (response: any) => {
          if (!response.data || !response.data.length) {
            store.setStrapiContent(null);
            await getFallbackStage();
          } else {
            store.setStrapiContent(response.data);
            await getFallbackStage();
          }
        })
        .catch((error: any) => {
          store.setStrapiContent(null);
        });
    } else {
      store.setStrapiContent(null);
    }

    //Fetch SEO stuff
    const seoUrl: string = `${
      app.$config.public.strapi.url
    }/api/${seoPath}?filters[Path][$eqi]=${encodeURIComponent(
      route.path
    )}&populate=deep,10`;

    await $fetch(seoUrl)
      .then((seoResponse: any) => {
        if (seoResponse.data.length) {
          store.setStrapiSeo(seoResponse.data[0].attributes);
        } else {
          store.setStrapiSeo(null);
        }
      })
      .catch((error: any) => {
        store.setStrapiSeo(null);
      });
  };

  return {getStrapiPage};
}
