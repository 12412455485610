<template>
  <div class="rating-average">
    <div
      v-for="(star, index) in averageRating"
      :key="index"
      class="rating-average__item icon-star-full"
    ></div>
    <div v-if="halfStar" class="rating-average__item icon-star-half"></div>
    <a href="#reviews" v-if="reviews" class="rating-average__link">
      {{ reviews }} {{ reviews > 1 ? 'Bewertungen' : 'Bewertung' }}
    </a>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  rating: {
    type: Number,
    default: 0,
  },
  reviews: {
    type: Number,
    default: null,
  },
});

const halfStar = ref(false);

const isInteger = computed(() => {
  if (Number.isInteger(props.rating)) {
    return true;
  } else {
    return false;
  }
});
const averageRating = computed(() => {
  if (isInteger.value) {
    return props.rating;
  } else {
    halfStar.value = true;
    return Math.floor(props.rating);
  }
});
</script>
<style lang="scss" scoped>
.rating-average {
  display: flex;
  align-items: center;
  gap: $spacing-xxxs;
}

.rating-average__item {
  color: $color-yellow;
}

.rating-average__link {
  color: $color-dark-gray;
  font-size: $text-sm;
  margin-left: $spacing-xs;
  font-weight: $font-light;
}
</style>
